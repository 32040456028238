import React from "react";
import moment from 'moment';
import MaterialTable from "@material-table/core";
import Header from "./Header";


// https://github.com/effiongcharles/material-ui-table-crud-restapi/blob/master/src/App.js


export const CoreSettings = () => {
    const HOST = 'https://redfish-warm-absolutely.ngrok-free.app';
    const { useState } = React;
    const { useEffect } = React;

    const [loading, setLoadingState] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        fetchData();
    }, [])

    function fetchData() {
        const url = HOST + '/getCoreSettings';
        const data = { "bot_name": localStorage.getItem("bot_name") }
        fetch(url, { // optional fetch options
            body: JSON.stringify(data), // you may send any data, encoded as you wish. shall match content-type 
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, same-origin, *omit
            headers: {
                'content-type': 'application/json'
            },
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            redirect: 'follow', // *manual, follow, error
            referrer: 'no-referrer', // *client, no-referrer
        })
            .then(response => response.json())
            .then(result => {
                setData(result.result);
                setLoadingState(false);
            });
    };

    var columns = [
        { title: "CA pattern length", field: "color_anomaly_pattern_length", align: "left", editable: "always", searchable: false, filtering: false },
        { title: "CA volume filter %", field: "color_anomaly_volume_filter_size", align: "left", editable: "always", searchable: false, filtering: false },
    ];

    return <div>
        {<Header />}
        {
            loading
                ?
                <div className="container loading-spinner pt-5 mt-5" style={{ width: "10rem", height: "10rem" }}>
                    <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                        viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                        <path fill="#919191" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                            <animateTransform
                                attributeName="transform"
                                attributeType="XML"
                                type="rotate"
                                dur="1s"
                                from="0 50 50"
                                to="360 50 50"
                                repeatCount="indefinite" />
                        </path>
                    </svg>
                </div>
                :
                <MaterialTable
                    columns={columns}
                    data={data}
                    title="Core Settings"
                    options={{
                        // addRowPosition: "first",
                        search: false,
                        columnsButton: false,
                        filtering: false,
                        pageSize: 20,
                        rowStyle: (rowData) => {
                            return {
                                backgroundColor: '#fff',
                                fontSize: "14px"
                            }
                        },
                        headerStyle: {
                            fontWeight: "bold"
                        },
                        filterCellStyle: {
                            paddingTop: "0px",
                            paddingBottom: "8px",
                        }
                    }}
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                if (newData.color_anomaly_pattern_length !== oldData.color_anomaly_pattern_length ||
                                    newData.color_anomaly_volume_filter_size !== oldData.color_anomaly_volume_filter_size
                                ) {
                                    const url = HOST + '/setCoreSettings';
                                    const _data = {
                                        "bot_name": localStorage.getItem("bot_name"), 
                                        "color_anomaly_pattern_length": newData.color_anomaly_pattern_length, 
                                        "color_anomaly_pattern_price_change": newData.color_anomaly_pattern_price_change
                                    }
                                    fetch(url, { // optional fetch options
                                        body: JSON.stringify(_data), // you may send any data, encoded as you wish. shall match content-type 
                                        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                                        credentials: 'same-origin', // include, same-origin, *omit
                                        headers: {
                                            'content-type': 'application/json'
                                        },
                                        method: 'POST', // *GET, POST, PUT, DELETE, etc.
                                        mode: 'cors', // no-cors, cors, *same-origin
                                        redirect: 'follow', // *manual, follow, error
                                        referrer: 'no-referrer', // *client, no-referrer
                                    })
                                        .then(response => response.json())
                                        .then(result => {
                                            if (result.result === "success") {
                                                const dataUpdate = [...data];
                                                const index = oldData.tableData.index;
                                                dataUpdate[index] = newData;
                                                setData([...dataUpdate]);
                                                resolve();
                                            }
                                        });
                                } else {
                                    reject();
                                }
                            })
                    }}
                />
        }
    </div>;
} 