import React from "react";
import moment from 'moment';
import MaterialTable from "@material-table/core";
import Header from "./Header";


// https://github.com/effiongcharles/material-ui-table-crud-restapi/blob/master/src/App.js


export const Signals = () => {
    const HOST = 'https://redfish-warm-absolutely.ngrok-free.app';
    const { useState } = React;
    const { useEffect } = React;

    const [loading, setLoadingState] = useState(true);
    const [data, setData] = useState([]);
    const typeLookup = { "SHORT": "SHORT", "LONG": "LONG" };
    const boolLookup = { 0: 'No', 1: 'Yes' };

    useEffect(() => {
        fetchData();
    }, [])

    function fetchData() {
        const url = HOST + '/signals';
        const data = { "bot_name": localStorage.getItem("bot_name") }
        fetch(url, { // optional fetch options
            body: JSON.stringify(data), // you may send any data, encoded as you wish. shall match content-type 
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, same-origin, *omit
            headers: {
                'content-type': 'application/json'
            },
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            redirect: 'follow', // *manual, follow, error
            referrer: 'no-referrer', // *client, no-referrer
        })
            .then(response => response.json())
            .then(result => {
                for (var _item of result.result) {
                    _item.date = moment(_item.timestamp).format("MMM DD, HH:mm:ss");
                    if (_item.pattern_first_candle_time) {
                        _item.pattern_first_candle_date = moment(_item.pattern_first_candle_time).format("MMM DD, HH:mm:ss");
                    }
                    if (_item.pattern_last_candle_time) {
                        _item.pattern_last_candle_date = moment(_item.pattern_last_candle_time).format("MMM DD, HH:mm:ss");
                    }
                    if (_item.pattern_price_change) {
                        _item.pattern_price_change = _item.pattern_price_change.toFixed(2);
                    }
                    if (_item.pattern_volume) {
                        _item.pattern_volume = parseInt(_item.pattern_volume);
                    }
                    
                }
                setData(result.result.reverse());
                setLoadingState(false);
            });
    };

    var columns = [
        { title: "ID", field: "id", align: "left", editable: "never" },
        { title: "Type", field: "type", align: "left", editable: "never", lookup: typeLookup },
        { title: "Symbol", field: "symbol", align: "left", editable: "never" },
        { title: "Date", field: "date", align: "left", editable: "never"},
        { title: "Timestamp", field: "timestamp", align: "left", editable: "never", hidden: true},
        { title: "Enter Price", field: "enter_price", align: "left", editable: "never", searchable: false, filtering: false},
        { title: "Pattern Length", field: "pattern_length", align: "left", editable: "never", hidden: true },
        { title: "Pattern End Time", field: "pattern_last_candle_time", align: "left", editable: "never", hidden: true},
        { title: "Pattern End Date", field: "pattern_last_candle_date", align: "left", editable: "never", hidden: false},
        { title: "Pattern Price Change %", field: "pattern_price_change", align: "left", editable: "never"},
        { title: "Pattern Volume", field: "pattern_volume", align: "left", editable: "never"},
        { title: "Interval", field: "interval", align: "left", editable: "never", filtering: true, hidden: true},
        { title: "Strategy", field: "strategy", align: "left", editable: "never", filtering: true, hidden: true},
        { title: "Market", field: "market", align: "left", editable: "never", filtering: true, hidden: true},
        { title: "Is Processed", field: "is_processed", align: "left", editable: "never", lookup: boolLookup },
    ];

    if (localStorage.getItem("bot_name") === "SB_BINANCE_COLOR_ANOMALY"  || localStorage.getItem("bot_name") === "HI_BINANCE_COLOR_ANOMALY") {
        
    }

    return <div>
        {<Header />}
        {
            loading
                ?
                <div className="container loading-spinner pt-5 mt-5" style={{ width: "10rem", height: "10rem" }}>
                    <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                        viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                        <path fill="#919191" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                            <animateTransform
                                attributeName="transform"
                                attributeType="XML"
                                type="rotate"
                                dur="1s"
                                from="0 50 50"
                                to="360 50 50"
                                repeatCount="indefinite" />
                        </path>
                    </svg>
                </div>
                :
                <MaterialTable
                    columns={columns}
                    data={data}
                    title="Signals"
                    options={{
                        // addRowPosition: "first",
                        search: false,
                        columnsButton: true,
                        filtering: true,
                        pageSize: 50,
                        rowStyle: (rowData) => {
                            var color = "#FFFFFF";
                            if (rowData.type === "SHORT") {
                                color = '#ffe0e0';
                            } else if (rowData.type === "LONG") {
                                color = '#e6fce6';
                            }
                            return {
                            backgroundColor: color,
                            fontSize: "14px"
                        }},
                        headerStyle: {
                            fontWeight: "bold"
                        },
                        filterCellStyle: {
                            paddingTop: "0px",
                            paddingBottom: "8px",
                        }
                    }}
                />
        }
    </div>;
} 