import React from "react";
import moment from 'moment';
import MaterialTable from "@material-table/core";
import Header from "./Header";


// https://github.com/effiongcharles/material-ui-table-crud-restapi/blob/master/src/App.js


export const Orders = () => {
    const HOST = 'https://redfish-warm-absolutely.ngrok-free.app';
    const { useState } = React;
    const { useEffect } = React;

    const [loading, setLoadingState] = useState(true);
    const [data, setData] = useState([]);
    const boolLookup = { 0: 'No', 1: 'Yes' };
    const sideLookup = { 'SELL': 'SELL', 'BUY': 'BUY' };
    const positionSideLookup = { 'SHORT': 'SHORT', 'LONG': 'LONG' };
    const typeLookup = {
        'LIMIT': 'LIMIT',
        'STOP_MARKET': 'STOP_MARKET',
        'TAKE_PROFIT_MARKET': 'TAKE_PROFIT_MARKET',
        'MARKET': 'MARKET',
        'TRAILING_STOP_MARKET': 'TRAILING_STOP_MARKET'
    };
    const statusLookup = {
        'NEW': 'NEW',
        'PARTIALLY_FILLED': 'PARTIALLY_FILLED',
        'FILLED': 'FILLED',
        'CANCELED': 'CANCELED',
        'REJECTED': 'REJECTED',
        'EXPIRED': 'EXPIRED',
        'EXPIRED_IN_MATCH': 'EXPIRED_IN_MATCH'
    };
    const internalTypeLookup = {
        'ENTER': 'ENTER',
        'TAKE_PROFIT': 'TAKE_PROFIT',
        'STOP_LOSS': 'STOP_LOSS',
        'ENTER_LIMIT': 'ENTER_LIMIT',
        'MARKET_CLOSE': 'MARKET_CLOSE',
        'TRAILING_STOP': 'TRAILING_STOP',
        'AVERAGE': 'AVERAGE'
    };

    useEffect(() => {
        fetchData();
    }, [])

    function fetchData() {
        const url = HOST + '/orders';
        const data = { "bot_name": localStorage.getItem("bot_name") }
        fetch(url, { // optional fetch options
            body: JSON.stringify(data), // you may send any data, encoded as you wish. shall match content-type 
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, same-origin, *omit
            headers: {
                'content-type': 'application/json'
            },
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            redirect: 'follow', // *manual, follow, error
            referrer: 'no-referrer', // *client, no-referrer
        })
            .then(response => response.json())
            .then(result => {
                for (var _item of result.result) {
                    _item.timestamp_date = moment(_item.timestamp).format("MMM DD, HH:mm");
                }
                setData(result.result.reverse());
                setLoadingState(false);
            });
    };


    const columns = [
        { title: "ID", field: "id", align: "left", editable: "never" },
        { title: "Custom Order ID", field: "orig_client_order_id", align: "left", editable: "never", hidden: true },
        { title: "Signal ID", field: "signal_id", align: "left", editable: "never", hidden: true },
        { title: "Deal ID", field: "deal_id", align: "left", editable: "never" },
        { title: "Symbol", field: "symbol", align: "left", editable: "never" },
        { title: "Side", field: "side", align: "left", editable: "never", lookup: sideLookup },
        { title: "Position Side", field: "position_side", align: "left", editable: "never", lookup: positionSideLookup },
        { title: "Type", field: "type", align: "left", editable: "never", lookup: typeLookup },
        { title: "Status", field: "status", align: "left", editable: "never", lookup: statusLookup },
        { title: "Date", field: "timestamp_date", align: "left", editable: "never" },
        { title: "Timestamp", field: "timestamp", align: "left", editable: "never", hidden: true },
        { title: "Time In Force", field: "time_in_force", align: "left", editable: "never", hidden: true },
        { title: "Quantity", field: "quantity", align: "left", editable: "never", searchable: false, filtering: false },
        { title: "Price", field: "price", align: "left", editable: "never", searchable: false, filtering: false },
        { title: "Avg Price", field: "avg_price", align: "left", editable: "never", searchable: false, filtering: false },
        { title: "Executed Quantity", field: "executed_quantity", align: "left", editable: "never", searchable: false, filtering: false },
        { title: "Stop Price", field: "stop_price", align: "left", editable: "never", searchable: false, filtering: false },
        { title: "Close Position", field: "close_position", align: "left", editable: "never", hidden: true, lookup: boolLookup },
        { title: "Good Till Date", field: "good_till_date", align: "left", editable: "never", hidden: true },
        { title: "Binance Order Id", field: "binance_order_id", align: "left", editable: "never", hidden: true },
        { title: "Activate Price", field: "activate_price", align: "left", editable: "never", searchable: false, filtering: false },
        { title: "Price Rate", field: "price_rate", align: "left", editable: "never", searchable: false, filtering: false, hidden: true },
        { title: "Internal Type", field: "internal_type", align: "left", editable: "never", lookup: internalTypeLookup },
        
    ];

    return <div>
        {<Header />}
        {
            loading
                ?
                <div className="container loading-spinner pt-5 mt-5" style={{ width: "10rem", height: "10rem" }}>
                    <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                        viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                        <path fill="#919191" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                            <animateTransform
                                attributeName="transform"
                                attributeType="XML"
                                type="rotate"
                                dur="1s"
                                from="0 50 50"
                                to="360 50 50"
                                repeatCount="indefinite" />
                        </path>
                    </svg>
                </div>
                :
                <MaterialTable
                    columns={columns}
                    data={data}
                    title="Orders"
                    options={{
                        // addRowPosition: "first",
                        search: false,
                        columnsButton: true,
                        filtering: true,
                        pageSize: 50,
                        rowStyle: (rowData) => {
                            console.log(rowData)
                            var color = "#FFFFFF";
                            return {
                                backgroundColor: color,
                                fontSize: "14px"
                            }
                        },
                        headerStyle: {
                            fontWeight: "bold"
                        },
                        filterCellStyle: {
                            paddingTop: "0px",
                            paddingBottom: "8px",
                        }
                    }}
                />
        }
    </div>;
} 