import React, { useState, useEffect } from "react";
// var alertShown = true;
export const SelectBotPage = () => {

    

    useEffect(() => {
        const _botName = localStorage.getItem("bot_name");
        if (_botName) {
            window.location.href = "/status";
        }
    }, []);

    const handleSubmit = async e => {
        const val = document.querySelector('#botSelector').value;
        if (val != "0") {
            localStorage.setItem('bot_name', val);
            window.location.href = "/status";
        }
    };

    // const handleSubmit = async e => {
    //     setErrorMsg(null);
    //     if (username === "" || password === "") {
    //         setErrorMsg("Please enter both username and password");
    //         return;
    //     }
    //     const url = 'https://adapi.motionbro.com/authenticate';
    //     const _data = { "username": username, "password": password };
    //     fetch(url, { // optional fetch options
    //         body: JSON.stringify(_data), // you may send any data, encoded as you wish. shall match content-type 
    //         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    //         credentials: 'same-origin', // include, same-origin, *omit
    //         headers: {
    //             'content-type': 'application/json'
    //         },
    //         method: 'POST', // *GET, POST, PUT, DELETE, etc.
    //         mode: 'cors', // no-cors, cors, *same-origin
    //         redirect: 'follow', // *manual, follow, error
    //         referrer: 'no-referrer', // *client, no-referrer
    //     })
    //         .then(response => response.json())
    //         .then(result => {
    //             console.log(result);
    //             if (result.result === "success") {
    //                 setSk(result.sk);
    //                 setRole(result.role);
    //                 localStorage.setItem('sk', result.sk);
    //                 localStorage.setItem('role', result.role);
    //                 window.location.href = "/codes";
    //             } else {
    //                 setErrorMsg("Incorrect username or password provided");
    //             }
    //         });
    // };
    // if (alertShown) {
    //     window.confirm("Не надо смотреть. Лучше закрой :)");
    //     alertShown = false;
    // }
    
    return <div>
        <section className="vh-100" style={{ backgroundColor: "#ccc" }}>
            <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                        <div className="card shadow-2-strong" style={{ borderRadius: "0.6rem" }}>
                            <div className="card-body p-4 text-center">

                                <h3 className="mb-4">Select a bot</h3>

                                <select id="botSelector" className="form-select" aria-label="Default select example">
                                    <option value="0">Select bot</option>
                                    {/* <option value="HI_BINANCE_FUTURES">HI_BINANCE_FUTURES</option> */}
                                    <option value="SI_BINANCE_FUTURES">SI_BINANCE_FUTURES</option>
                                </select>

                                {/* <div className="form-outline mb-4">
                                    <input placeholder="Username" type="email" id="typeEmailX-2" className="form-control form-control-lg" onChange={({ target }) => setUsername(target.value)} />
                                </div>

                                <div className="form-outline">
                                    <input placeholder="Password" type="password" id="typePasswordX-2" className="form-control form-control-lg" onChange={({ target }) => setPassword(target.value)} />
                                </div>

                                {
                                    errorMsg ? <div className="mt-3" style={{ color: "red" }}>{errorMsg}</div> : <div></div>
                                } */}

                                <button className="btn btn-success btn-lg btn-block mt-4" type="submit" onClick={handleSubmit}>GO</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>;
}