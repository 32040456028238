import React from "react";
import moment from 'moment';
import MaterialTable from "@material-table/core";
import Header from "./Header";


// https://github.com/effiongcharles/material-ui-table-crud-restapi/blob/master/src/App.js


export const Status = () => {
    const HOST = 'https://redfish-warm-absolutely.ngrok-free.app';
    const { useState } = React;
    const { useEffect } = React;

    const [loading, setLoadingState] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        fetchData();
    }, [])

    function fetchData() {
        const url = HOST + '/healthInfo';
        const data = { "bot_name": localStorage.getItem("bot_name") }
        fetch(url, { // optional fetch options
            body: JSON.stringify(data), // you may send any data, encoded as you wish. shall match content-type 
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, same-origin, *omit
            headers: {
                'content-type': 'application/json'
            },
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            redirect: 'follow', // *manual, follow, error
            referrer: 'no-referrer', // *client, no-referrer
        })
            .then(response => response.json())
            .then(result => {
                for (var _item of result.result) {
                    // _item.last_signal_add_time_converted = moment(_item.last_signal_add_time).format("MMM DD, HH:mm:ss");
                    _item.last_signals_process_time_converted = moment(_item.last_signals_process_time).format("MMM DD, HH:mm:ss");
                    _item.last_orders_process_time_converted = moment(_item.last_orders_process_time).format("MMM DD, HH:mm:ss");
                    _item.short_name = _item.name.slice(0,24);
                }
                setData(result.result);
                setLoadingState(false);
            });
    };


    var columns = [
        { title: "Bot Name", field: "short_name", align: "left", editable: "never", searchable: false, filtering: false },
        { title: "Bot Version", field: "version", align: "left", editable: "never", searchable: false, filtering: false },
        // {
        //     title: "Last Signal Added At", field: "last_signal_add_time_converted", align: "left", editable: "never", searchable: false, filtering: false, cellStyle: (e, rowData) => {
        //         if (moment().unix() - rowData.last_signal_add_time > 6 * 60 * 60) {
        //             return { backgroundColor: "#ffe0e0" };
        //         } else {
        //             return { backgroundColor: "#e6fce6" };
        //         }
        //     }
        // },
        {
            title: "Last Signals Process At", field: "last_signals_process_time_converted", align: "left", editable: "never", searchable: false, filtering: false, cellStyle: (e, rowData) => {
                if (moment().unix() - rowData.last_signals_process_time > 2 * 60) {
                    return { backgroundColor: "#ffe0e0" };
                } else {
                    return { backgroundColor: "#e6fce6" };
                }
            }
        },
        {
            title: "Last Orders Process At", field: "last_orders_process_time_converted", align: "left", editable: "never", searchable: false, filtering: false, cellStyle: (e, rowData) => {
                if (moment().unix() - rowData.last_orders_process_time > 5 * 60) {
                    return { backgroundColor: "#ffe0e0" };
                } else {
                    return { backgroundColor: "#e6fce6" };
                }
            },
        }
    ];

    if (localStorage.getItem("bot_name") === "SB_BINANCE_COLOR_ANOMALY" || localStorage.getItem("bot_name") === "HI_BINANCE_COLOR_ANOMALY") {
        columns = [
            { title: "Bot Name", field: "short_name", align: "left", editable: "never", searchable: false, filtering: false },
            { title: "Bot Version", field: "version", align: "left", editable: "never", searchable: false, filtering: false },
            {
                title: "Last Signals Process At", field: "last_signals_process_time_converted", align: "left", editable: "never", searchable: false, filtering: false, cellStyle: (e, rowData) => {
                    if (moment().unix() - rowData.last_signals_process_time > 2 * 60) {
                        return { backgroundColor: "#ffe0e0" };
                    } else {
                        return { backgroundColor: "#e6fce6" };
                    }
                }
            },
            {
                title: "Last Orders Process At", field: "last_orders_process_time_converted", align: "left", editable: "never", searchable: false, filtering: false, cellStyle: (e, rowData) => {
                    if (moment().unix() - rowData.last_orders_process_time > 5 * 60) {
                        return { backgroundColor: "#ffe0e0" };
                    } else {
                        return { backgroundColor: "#e6fce6" };
                    }
                },
            }
        ];
    }

    return <div>
        {<Header />}
        {
            loading
                ?
                <div className="container loading-spinner pt-5 mt-5" style={{ width: "10rem", height: "10rem" }}>
                    <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                        viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                        <path fill="#919191" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                            <animateTransform
                                attributeName="transform"
                                attributeType="XML"
                                type="rotate"
                                dur="1s"
                                from="0 50 50"
                                to="360 50 50"
                                repeatCount="indefinite" />
                        </path>
                    </svg>
                </div>
                :
                <MaterialTable
                    columns={columns}
                    data={data}
                    title="Status"
                    options={{
                        // addRowPosition: "first",
                        search: false,
                        columnsButton: false,
                        filtering: false,
                        pageSize: 20,
                        rowStyle: (rowData) => {
                            return {
                                backgroundColor: '#fff',
                                fontSize: "14px"
                            }
                        },
                        headerStyle: {
                            fontWeight: "bold"
                        },
                        filterCellStyle: {
                            paddingTop: "0px",
                            paddingBottom: "8px",
                        }
                    }}
                />
        }
    </div>;
} 